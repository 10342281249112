import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import truncate from 'lodash/truncate';
import { getProductIdFromUrl } from '../../helpers/url';
import {
  productClickEvent,
  triggerSuperGiftCardClickEvent,
  triggerSeeAllGiftCardClickEvent,
} from '../../helpers/googleTagManager';

import s from './Home.scss';
import Page from '../../components/Page';
import Link from '../../components/Link';
import Icon from '../../components/Icon';
import Grid from '../../components/Grid';
import Carousel from '../../components/Carousel';
import {
  ICONS,
  GLOBAL_GIFTCARD_PATH,
  REDEEM_PATH,
  SHOP_PATH,
  OCCASIONS_PATH,
  ACCOUNT_TYPE,
  SALES_CHANNELS,
} from '../../constants';
import Clickable from '../../components/Clickable';
import Slider from '../../components/Slider';
import withResponsiveWrapper from '../../decorators/withResponsiveWrapper';
import Card from '../../components/Card';
import SliderCard from './components/Card';
import { homeMessages as messages } from '../../defineMessages';
import Label from '../../components/Label';
import { urlPrefix, sortBy } from '../../helpers';

const Home = ({
  recipients,
  popularProducts,
  popularCategories,
  popularOccasions,
  banners,
  isDesktop,
  intl: { formatMessage },
  loading,
  mode,
  presentations,
  locale,
  countryCode,
  currency,
  salesChannelId,
  redirectToShop,
}) => {
  // Used to prevent getting caught in a redirect loop.
  const hasRedirectedToShopRef = useRef(false);

  // This should be done in a parent component, but is unfortunately not possible with the current router setup.
  if (
    !hasRedirectedToShopRef.current &&
    salesChannelId !== SALES_CHANNELS.B2C
  ) {
    hasRedirectedToShopRef.current = true;
    redirectToShop();
  }

  // Move to own component
  const restructuredRecipients = Object.keys(recipients)
    .filter(recipientId => recipients[recipientId]?.includeInPopular)
    .map(recipientId => ({
      id: recipientId,
      ...recipients[recipientId],
    }));

  const Banner = ({ banner }) => {
    const slide = (
      <div className={s.hero}>
        <img
          src={`${banner.image}`}
          alt="banner"
          title={banner.link ? '' : banner.title || ''}
          className={s.hero__content}
        />
      </div>
    );

    return banner.link ? (
      <a
        href={urlPrefix(banner.link, { countryCode, currency, locale })}
        title={banner.title || ''}
      >
        {slide}
      </a>
    ) : (
      slide
    );
  };

  Banner.propTypes = {
    banner: PropTypes.shape().isRequired,
  };

  const gtmProductClickEvent = (id, productTitle, price, index) => {
    productClickEvent({
      eventData: {
        currencyCode: currency,
        click: {
          actionField: {
            list: 'home',
            salesChannelId,
            mode,
          },
          products: [
            {
              id,
              name: productTitle,
              price,
              position: index + 1,
            },
          ],
        },
      },
    });
  };
  const gtmSuperGiftCardClickEvent = suparcardPresenatation => {
    const productId = getProductIdFromUrl(
      suparcardPresenatation?.countryLinkSuperGiftcard,
    );
    if (productId) {
      const product = popularProducts?.find(prod => prod.id === productId);
      triggerSuperGiftCardClickEvent({
        eventData: {
          currencyCode: currency,
          click: {
            actionField: {
              list: 'home',
              salesChannelId,
              mode,
            },
            products: [
              {
                id: productId,
                name: product?.productTitle,
              },
            ],
          },
        },
      });
    }
  };

  const gtmSeeAllGiftCardEvent = () => {
    triggerSeeAllGiftCardClickEvent({
      eventData: {
        currencyCode: currency,
        actionField: {
          list: 'home',
          salesChannelId,
          mode,
        },
      },
    });
  };

  return (
    <Page loading={loading}>
      <Grid container spacing={16} className={s.header}>
        <Grid item xs={24} md={8} lg={6} className={s.linkContainer}>
          {mode === ACCOUNT_TYPE.B2B && (
            <Link
              to={GLOBAL_GIFTCARD_PATH}
              className={cx(s.cta, s['cta--blue'])}
            >
              <span>
                <span className={s.cta__main}>
                  {formatMessage({ ...messages.linkSuperGiftcard })}
                </span>
                <span className={s.cta__desc}>
                  {formatMessage({ ...messages.linkSuperGiftcardDesc })}
                </span>
              </span>
            </Link>
          )}
          {mode !== ACCOUNT_TYPE.REDEEM &&
            presentations.countryLinkSuperGiftcard && (
              <Link
                to={presentations.countryLinkSuperGiftcard}
                className={cx(s.cta, s['cta--blue'])}
                onClick={() => {
                  gtmSuperGiftCardClickEvent(presentations);
                }}
              >
                <span>
                  <span className={s.cta__main}>
                    {formatMessage({
                      ...messages.linkCountrySuperGiftcard,
                    })}
                  </span>
                  <span className={s.cta__desc}>
                    {formatMessage({
                      ...messages.linkCountrySuperGiftcardDesc,
                    })}
                  </span>
                </span>
              </Link>
            )}
          {mode !== ACCOUNT_TYPE.B2B && (
            <Link to={REDEEM_PATH} className={cx(s.cta, s['cta--gray'])}>
              <span>
                <span className={s.cta__main}>
                  {formatMessage({ ...messages.redeemSuperGiftcard })}
                </span>
                <span className={s.cta__desc}>
                  {formatMessage({ ...messages.redeemSuperGiftcardDesc })}
                </span>
              </span>
            </Link>
          )}
          <Link
            to={SHOP_PATH}
            className={cx(s.cta, s['cta--dark'])}
            onClick={gtmSeeAllGiftCardEvent}
          >
            <span>
              <span className={s.cta__main}>
                {formatMessage({ ...messages.seeAll })}
              </span>
              <span className={s.cta__desc}>
                {formatMessage({ ...messages.seeAllDesc })}
              </span>
            </span>
          </Link>
        </Grid>
        <Grid item xs={24} md={16} lg={18}>
          <Carousel
            rootClass={s.carousel}
            dotsClass={s.dots}
            dots
            arrows
            autoPlay
          >
            {banners &&
              banners.length &&
              banners.map((banner, ix) => (
                <Banner banner={banner} key={ix.toString()} />
              ))}
          </Carousel>
        </Grid>
      </Grid>

      {restructuredRecipients?.length > 0 && (
        <section className={cx(s.section, s.recipients)}>
          <Grid container spacing={16} alignContent="center" justify="center">
            {restructuredRecipients
              .sort(sortBy('categorySortingHome'))
              .map(recipient => (
                <Grid key={recipient.id} item xs={12} sm="auto">
                  <Link
                    to={`${SHOP_PATH}?recipient=${recipient.id}`}
                    className={s.recipients__link}
                  >
                    <Label bold uppercase>
                      {recipient.label}
                    </Label>
                    <span className={s.arrow}>
                      <Icon name={ICONS.CHEVRON_RIGHT} size={8} fill="#fff" />
                    </span>
                  </Link>
                </Grid>
              ))}
          </Grid>
        </section>
      )}

      <section className={s.section}>
        <Slider
          naturalSlideHeight={132}
          visibleSlides={isDesktop ? 4 : 2}
          hoverType={isDesktop ? 'box-shadow' : ''}
          arrows
        >
          {popularCategories
            .sort(sortBy('categorySortingHome'))
            .map(({ id, categoryLogo, categoryTranslatedName }) => (
              <SliderCard
                key={id}
                title={categoryTranslatedName}
                image={
                  categoryLogo &&
                  categoryLogo[0] &&
                  (categoryLogo[0].source || categoryLogo[0].thumbnail || '')
                }
                hoverType={isDesktop ? 'box-shadow-and-overlay' : ''}
                link={<Link to={`${SHOP_PATH}?category=${id}`} />}
                cta={formatMessage({ ...messages.shopAll })}
              />
            ))}
        </Slider>
      </section>

      <section className={s.section}>
        <header className={s.section__header}>
          <h2 className={s.section__label}>
            {isDesktop
              ? formatMessage({ ...messages.popularGiftsLong })
              : formatMessage({ ...messages.popularGifts })}
          </h2>
        </header>
        {popularProducts && (
          <Grid container spacing="fluid">
            {popularProducts.map(
              (
                {
                  id,
                  productLogo,
                  productTitle,
                  productSlug,
                  price,
                  ...productProps
                },
                ix,
              ) => (
                <Grid item xs={12} sm={6} md={4} key={ix.toString()}>
                  <Card
                    {...productProps}
                    hoverType={isDesktop ? 'box-shadow' : ''}
                    productTitle={productTitle}
                    price={price}
                    productLogo={
                      productLogo && productLogo[0]
                        ? productLogo[0].thumbnail
                        : ''
                    }
                    link={
                      <Link
                        to={`${SHOP_PATH}/${productSlug}/${id}`}
                        onClick={() => {
                          gtmProductClickEvent(id, productTitle, price, ix);
                        }}
                      />
                    }
                  />
                </Grid>
              ),
            )}
          </Grid>
        )}
        <footer className={s.section__footer}>
          <Clickable
            primary
            link={<Link to={SHOP_PATH} onClick={gtmSeeAllGiftCardEvent} />}
            className={s.section__button}
          >
            {formatMessage({ ...messages.seeAllGiftcards })}
          </Clickable>
        </footer>
      </section>

      {popularOccasions.length > 0 && (
        <section className={s.section}>
          <header className={s.section__header}>
            <h2 className={s.section__label}>
              {formatMessage({ ...messages.shopAfterOccasion })}
            </h2>
          </header>
          <Slider
            naturalSlideHeight={109}
            visibleSlides={isDesktop ? 6 : 2}
            hoverType={isDesktop ? 'box-shadow' : ''}
            arrows
            largeArrows
          >
            {popularOccasions.map(
              ({ id, categoryTranslatedName, categorySlug, categoryLogo }) => (
                <SliderCard
                  hoverType={isDesktop ? 'box-shadow-and-overlay' : ''}
                  key={id}
                  title={truncate(categoryTranslatedName, {
                    length: 42,
                    separator: ' ',
                  })}
                  image={
                    categoryLogo &&
                    categoryLogo[0] &&
                    (categoryLogo[0].source || categoryLogo[0].thumbnail || '')
                  }
                  link={<Link to={`${OCCASIONS_PATH}/${categorySlug}/${id}`} />}
                />
              ),
            )}
          </Slider>
          <footer className={s.section__footer}>
            <Clickable
              primary
              link={<Link to={OCCASIONS_PATH} />}
              className={s.section__button}
            >
              {formatMessage({ ...messages.seeAllOccasions })}
            </Clickable>
          </footer>
        </section>
      )}
    </Page>
  );
};

Home.propTypes = {
  popularCategories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  popularProducts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  popularOccasions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  recipients: PropTypes.shape().isRequired,
  intl: PropTypes.shape().isRequired,
  banners: PropTypes.arrayOf(PropTypes.shape()),
  mode: PropTypes.string.isRequired,
  presentations: PropTypes.shape().isRequired,
  locale: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  salesChannelId: PropTypes.string.isRequired,
  redirectToShop: PropTypes.func.isRequired,
};

Home.defaultProps = {
  banners: [],
};

const mapState = state => {
  const {
    home: { popularProducts, popularCategories, popularOccasions, loading },
    config: { presentations, salesChannelId },
    user: { mode },
    intl: { countryCode, currency, locale },
  } = state;
  return {
    ...state.page.byName.home,
    popularCategories,
    popularProducts,
    popularOccasions,
    loading,
    countryCode,
    currency,
    locale,
    recipients: state.shop.filters.recipient,
    mode,
    presentations: presentations[state.intl.countryCode],
    salesChannelId,
  };
};

export default compose(
  withStyles(s),
  connect(mapState),
  withResponsiveWrapper(768),
  injectIntl,
)(Home);
