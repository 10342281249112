import React from 'react';
import Home from './Home';
import Layout from '../../components/Layout';
import { getPagePresentationKeys } from '../../actions/page';
import { SALES_CHANNELS, SHOP_PATH, QUERY_PARAMS } from '../../constants';
import { urlPrefix } from '../../helpers';
import { redirectTo } from '../../actions/config';

const pageName = 'home';
async function action({
  store,
  query: { [QUERY_PARAMS.SHOW_GLOBAL_LINK]: showGlobalLink } = {},
}) {
  const {
    intl: { countryCode, locale, currency },
    config: { salesChannelId },
  } = store.getState();
  const {
    byName: {
      [pageName]: { pageTitle, pageDescription },
    },
  } = await store.dispatch(getPagePresentationKeys({ pageName }));

  const redirect =
    salesChannelId !== SALES_CHANNELS.B2C
      ? urlPrefix(`${SHOP_PATH}`, {
          locale,
          countryCode,
          currency,
        })
      : undefined;

  // Unfortunately this 'action' is only run once and it doesn't react to store changes.
  // We therefore leave it up to the child component to redirect or not, since we cannot trust any store values here.
  const redirectToShop = () =>
    store.dispatch(redirectTo({ pathname: '/shop' }));

  return {
    title: pageTitle,
    description: pageDescription,
    chunks: ['home'],
    component: (
      <Layout footerBar redeemOverview showGlobalLink={showGlobalLink}>
        <Home redirectToShop={redirectToShop} />
      </Layout>
    ),
    redirect,
  };
}

export default action;
