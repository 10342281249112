/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './Card.scss';
import Clickable from '../../../../components/Clickable';

const Card = ({ title, cta, image, link, hoverType }) => (
  <link.type
    {...link.props}
    className={cx(s['slider-card'], hoverType && s[`hover-type--${hoverType}`])}
  >
    <div
      className={s['slider-card__image']}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={s['slider-card__title']}>
        <h3>{title}</h3>
      </div>
    </div>
    {cta && (
      <div className={s['slider-card__cta']}>
        <Clickable tag="span" primary size="slim">
          {cta}
        </Clickable>
      </div>
    )}
  </link.type>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  cta: PropTypes.string,
  link: PropTypes.node.isRequired,
  hoverType: PropTypes.string,
};

Card.defaultProps = {
  cta: undefined,
  hoverType: '',
  image: '',
};

export default withStyles(s)(Card);
